import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '@hooks';
import AuthContext from '@contexts/AuthContext';
import {
  makeStyles, AppBar, Toolbar, IconButton, Hidden,
} from '@material-ui/core';
import { Menu as MenuIcon, HelpOutline as HelpIcon } from '@material-ui/icons';
import InputIcon from '@material-ui/icons/Input';
import PropTypes from 'prop-types';
import images from '../../../assets/images';

const MainHeader = ({ mobileOpen, setMobileOpen }) => {
  const classes = useStyles();
  const { logout } = useAuth();
  const { auth } = useContext(AuthContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolBar}>
        <RouterLink to="/">
          <img
            src={images.logo2}
            alt="Logo Caltex"
            className={classes.logo}
          />
        </RouterLink>
        <div>
          <Hidden xsDown>
            <IconButton
              color="inherit"
            >
              <RouterLink style={{ color: '#fff' }} to={auth?.super_rol === 'customer' ? '/c/tutoriales' : '/e/tutoriales'}>
                <HelpIcon />
              </RouterLink>
            </IconButton>
          </Hidden>
          <Hidden xsDown>
            <IconButton
              color="inherit"
              onClick={logout}
            >
              <InputIcon />
            </IconButton>
          </Hidden>
          <Hidden smUp>
            <IconButton
              color="inherit"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </div>
      </Toolbar>
    </AppBar>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      marginLeft: drawerWidth,
    },
  },
  logo: {
    width: 52,
    height: 52,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

MainHeader.propTypes = {
  mobileOpen: PropTypes.bool,
  setMobileOpen: PropTypes.func,
};

MainHeader.defaultProps = {
  mobileOpen: false,
  setMobileOpen: () => {},
};

export default MainHeader;
