import React, { useState, useEffect, useContext } from 'react';
import {
  Grid, Hidden, IconButton, Menu, MenuItem, LinearProgress,
} from '@material-ui/core';
import {
  GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton,
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import {
  EditOutlined as EditarIcon,
  VisibilityOutlined as VerIcon,
  ListOutlined as MenuIcon,
  InsertDriveFileOutlined as InsertDriveFileOutlinedIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { usePresupuestos, useStates } from '@hooks';
import { Alert, ChipStep, DataGrid } from '@components/atoms';
import getDate from '@helpers/utils/getDate';
import constantsPresupuesto from '@helpers/constants/presupuesto';

import AuthContext from '@contexts/AuthContext';
import i18n from '@i18n';

const List = () => {
  const { pages: { employees: { presupuestos: { list: texts } } } } = i18n;
  const history = useHistory();
  const classes = useStyles();
  const { getPresupuestos, isPresupuestoLoading, presupuestoError } = usePresupuestos();
  const { getStates, isStateLoading, stateError } = useStates();
  const [presupuestos, setPresupuestos] = useState(null);
  const [states, setStates] = useState([]);
  const [actionSelected, setActionSelected] = useState(null);
  const { user } = useContext(AuthContext);

  useEffect(async () => {
    setPresupuestos(await getPresupuestos());
    setStates(await getStates());
  }, []);
  const handleClose = () => {
    setActionSelected(null);
  };

  const CustomToolbar = () => (
    <>
      <Hidden xsDown>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
      <Hidden smUp>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
    </>
  );

  const columns = [
    {
      field: 'contact',
      headerName: texts.columns.customer,
      flex: 0.5,
      valueGetter: (values) => values.row.contact.customer.name,
    },
    {
      field: 'number',
      headerAlign: 'center',
      headerName: texts.columns.number,
      align: 'center',
      flex: 0.4,
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      type: 'date',
      valueFormatter: (values) => getDate(values.value),
    },
    {
      field: 'state',
      headerName: texts.columns.state,
      renderCell: (values) => {
        const { row: { state: { name } } } = values;
        const color = constantsPresupuesto.STATES_PRESUPUESTO.find((item) => item.step === name);

        return (name ? (
          <ChipStep label={name} backgroundColor={color.backgroundColor} />
        ) : null);
      },
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: (values) => {
        const { row: { fileURL } } = values;
        const currentState = states.find((state) => state.id === values.row.state.id);
        return (
          <>
            <Hidden xsDown>
              {currentState.editAllowed && user && user.permissions.presupuesto.update && (
                <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/presupuestos/edit/${values.row.id}`)}>
                  <EditarIcon />
                </IconButton>
              )}
              {user && user.permissions.presupuesto.read && (
                <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/presupuestos/view/${values.row.id}`)}>
                  <VerIcon />
                </IconButton>
              )}
              <IconButton
                className={classes.actionIcons}
                onClick={() => window.open(fileURL, '_blank')}
              >
                <InsertDriveFileOutlinedIcon />
              </IconButton>
            </Hidden>
            <Hidden smUp>
              <IconButton
                className={classes.actionIcons}
                onClick={(event) => setActionSelected({ anchorEl: event.currentTarget, id: values.row.id })}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={actionSelected && actionSelected.anchorEl}
                /* eslint-disable react/destructuring-assignment */
                open={actionSelected && actionSelected.id === values.row.id}
                onClose={handleClose}
              >
                {currentState.editAllowed && user && user.permissions.presupuesto.update && (
                  <MenuItem onClick={() => history.push(`/e/presupuestos/edit/${values.row.id}`)}>Editar</MenuItem>
                )}
                {user && user.permissions.presupuesto.read && (
                  <MenuItem onClick={() => history.push(`/e/presupuestos/view/${values.row.id}`)}>Ver</MenuItem>
                )}
                <MenuItem onClick={() => window.open(fileURL, '_blank')}>Archivo</MenuItem>
              </Menu>
            </Hidden>
          </>
        );
      },
    },
  ];

  return (
    <>
      {presupuestoError && <Alert>{presupuestoError.message}</Alert>}
      {stateError && <Alert>{stateError.message}</Alert>}
      {(isPresupuestoLoading || isStateLoading) && <LinearProgress />}
      {presupuestos && states.length > 0 && (
        <Grid container spacing={2}>
          <DataGrid
            sortModel={[{ field: 'createdAt', sort: 'desc' }]}
            columns={columns}
            rows={presupuestos}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles({
  actionIcons: {
    padding: '4px',
  },
});

export default List;
