import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, FormControl, TextField, FormControlLabel, LinearProgress, InputLabel, FormHelperText,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import i18n from '@i18n';
import {
  useContacts, useCustomers, usePresupuestos, useAnalisis,
} from '@hooks';
import {
  Alert, ButtonAdorned, Subtitle, MultiFileUpload, Stepper, Autocomplete, Switch, Select, DatePicker,
} from '@components/atoms';
import { createAnalisisSchema } from '@helpers/schemas';
import constants from '@helpers/constants';
import moment from 'moment';

const FichaIngreso = ({ data: analisis }) => {
  const { pages: { employees: { analisis: { fichaingreso: texts } } } } = i18n;
  const history = useHistory();
  const { isContactLoading, contactError } = useContacts();
  const { getCustomers, isCustomerLoading, customerError } = useCustomers();
  const {
    isPresupuestoLoading, presupuestoError, getPresupuestos,
  } = usePresupuestos();
  const {
    isAnalisisLoading, analisisError, editAnalisis, getFiles,
  } = useAnalisis();
  const [customers, setCustomers] = useState([]);
  const [presupuestos, setPresupuestos] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const form = useFormik({
    initialValues: { ...analisis, typeArea: { id: analisis.area, name: analisis.area }, limitDate: moment(analisis.limitDate.toString().replace('Z', '')).format('YYYY-MM-DD') },
    enableReinitialize: true,
    validationSchema: createAnalisisSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      if (values.agreement && values.presupuesto) {
        enqueueSnackbar('No debe ingresar presupuesto si activa convenio', { variant: 'warning' });
        return;
      }
      if (!values.agreement && !values.presupuesto) {
        enqueueSnackbar('Debe ingresar presupuesto si no activa convenio', { variant: 'warning' });
        return;
      }
      const request = {
        id: values.id,
        customer: values.customer,
        presupuesto: values.agreement ? undefined : values.presupuesto,
        agreement: values.agreement,
        number: values.number,
        sample: values.sample,
        limitDate: values.limitDate,
        area: values.typeArea.id,
        typeArea: values.typeArea,
        files: values.docsFichaIngreso,
        filesType: 'ingreso',
        step: 'fichaingreso',
      };
      const edited = await editAnalisis(request);
      if (edited) {
        enqueueSnackbar(texts.saved, { variant: 'success' });
      }
    },
  });

  useEffect(async () => {
    setCustomers(await getCustomers());
    setPresupuestos(await getPresupuestos(`?contact.customer.id=${analisis.customer?.id}&state.id=${constants.STATE.APROBADO}`));
    const docsFichaIngreso = await getFiles({ customerId: analisis.customer.id, analisisId: analisis.id, filesType: 'ingreso' });
    form.setFieldValue('docsFichaIngreso', docsFichaIngreso);
  }, []);

  const onChangeCustomer = async (params, value) => {
    form.setFieldValue('presupuesto', '');
    form.setFieldValue('customer', value);
    form.setFieldValue('agreement', value?.agreement || false);
    setPresupuestos([]);
    if (value && value.id) {
      setPresupuestos(await getPresupuestos(`?contact.customer.id=${value.id}&state.id=${constants.STATE.APROBADO}`));
    }
  };
  const onChangeAgreement = async (_, value) => {
    form.setFieldValue('agreement', value);
    if (value) {
      form.setFieldValue('presupuesto', '');
    }
  };

  const handleContinue = async () => {
    const response = await editAnalisis({
      id: analisis.id,
      step: 'asignacionlab',
      sample: form.values.sample,
      area: form.values.typeArea.id,
      typeArea: form.values.typeArea,
      limitDate: form.values.limitDate,
    });

    if (response) {
      enqueueSnackbar(texts.success, { variant: 'success' });
      history.push('/e/analisis');
    }
  };

  return (
    <>
      {customerError && <Alert>{customerError.message}</Alert>}
      {contactError && <Alert>{contactError.message}</Alert>}
      {presupuestoError && <Alert>{presupuestoError.message}</Alert>}
      {analisisError && <Alert>{analisisError.message}</Alert>}
      {(isAnalisisLoading || isContactLoading || isPresupuestoLoading || isCustomerLoading) ? <LinearProgress /> : (
        <>
          <Stepper activeStep="fichaingreso" steps={constants.STEPS_ANALISIS} />
          <Grid container spacing={2}>
            {texts.subtitleData && (
              <Grid item xs={12} align="left">
                <Subtitle>{texts.subtitleData}</Subtitle>
              </Grid>
            )}
            <Grid item xs={11} sm={5} md={5}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  id={(Math.floor(Math.random() * (100 - 0 + 1)) + 0).toLocaleString()}
                  key={(Math.floor(Math.random() * (100 - 0 + 1)) + 0).toLocaleString()}
                  defaultValue={form.values.customer}
                  label={texts.customer}
                  onChange={onChangeCustomer}
                  error={form.touched.customer && Boolean(form.errors.customer)}
                  disabled={isCustomerLoading || isAnalisisLoading}
                  helperText={form.touched.customer && form.errors.customer && form.errors.customer.id}
                  items={customers}
                  close
                />
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={5} md={5}>
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  id={(Math.floor(Math.random() * (100 - 0 + 1)) + 0).toLocaleString()}
                  key={(Math.floor(Math.random() * (100 - 0 + 1)) + 0).toLocaleString()}
                  defaultValue={form.values.presupuesto}
                  label={texts.presupuesto}
                  onChange={(_, value) => form.setFieldValue('presupuesto', value)}
                  error={form.touched.presupuesto && Boolean(form.errors.presupuesto)}
                  disabled={isPresupuestoLoading || isAnalisisLoading || form.values.agreement}
                  helperText={(form.values.customer && presupuestos.length === 0 && !isPresupuestoLoading && texts.noPresupuestos)
                    || (form.touched.presupuesto && form.errors.presupuesto && form.errors.presupuesto.id)}
                  items={presupuestos}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              {form.values.customer?.agreement && (
                <FormControlLabel
                  control={(
                    <Switch
                      name="agreement"
                      onChange={onChangeAgreement}
                      disabled={isCustomerLoading}
                      checked={form.values.agreement}
                    />
                    )}
                  label={texts.agreement}
                />
              ) }
            </Grid>
            <Grid item xs={11} sm={11} md={5}>
              <FormControl fullWidth>
                <TextField
                  name="number"
                  label={texts.number}
                  placeholder={texts.numberPlaceholder}
                  variant="outlined"
                  value={form.values.number}
                  onChange={form.handleChange}
                  error={form.touched.number && Boolean(form.errors.number)}
                  helperText={form.touched.number && form.errors.number}
                  disabled={isContactLoading || isAnalisisLoading}
                  autoComplete="new-number"
                />
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={5} md={5}>
              <FormControl fullWidth>
                <TextField
                  name="sample"
                  label={texts.sample}
                  placeholder={texts.samplePlaceholder}
                  variant="outlined"
                  value={form.values.sample}
                  onChange={form.handleChange}
                  error={form.touched.sample && Boolean(form.errors.sample)}
                  helperText={form.touched.sample && form.errors.sample}
                  disabled={isContactLoading || isAnalisisLoading}
                  autoComplete="new-sample"
                />
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={5} md={5}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-type-area" id={texts.typeArea}>{texts.typeArea}</InputLabel>
                <Select
                  label={texts.typeArea}
                  name="typeArea"
                  variant="outlined"
                  value={form.values.typeArea}
                  onChange={form.handleChange}
                  inputProps={{
                    name: 'typeArea',
                    id: 'outlined-type-area',
                  }}
                  error={form.touched.typeArea && Boolean(form.errors.typeArea)}
                  disabled={isAnalisisLoading}
                  items={constants.TYPE_AREA}
                />
                {form.errors.typeArea && form.errors.typeArea.id && <FormHelperText error>{form.errors.typeArea.id}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={5} md={5}>
              <DatePicker
                label="Fecha límite"
                value={form.values.limitDate}
                onChange={(date) => form.setFieldValue('limitDate', date.target.value)}
              />
            </Grid>
            <Grid item xs={11} sm={5} md={5}>
              <FormControl fullWidth>
                <MultiFileUpload
                  title={texts.docsFichaIngreso}
                  name="docsFichaIngreso"
                  onChange={form.handleChange}
                  value={form.values.docsFichaIngreso}
                  error={form.touched.docsFichaIngreso && Boolean(form.errors.docsFichaIngreso)}
                  helperText={form.touched.docsFichaIngreso && form.errors.docsFichaIngreso && form.errors.docsFichaIngreso}
                  multiple
                  disabled={isAnalisisLoading}
                />
              </FormControl>
            </Grid>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <ButtonAdorned color="default" onClick={() => history.push('/e/analisis')}>
                  {texts.list}
                </ButtonAdorned>
              </Grid>
              <Grid item>
                <form onSubmit={form.handleSubmit}>
                  <ButtonAdorned loading={isAnalisisLoading} disabled={isAnalisisLoading} type="submit">
                    {texts.save}
                  </ButtonAdorned>
                </form>
              </Grid>
              <Grid item>
                <ButtonAdorned color="secondary" loading={isAnalisisLoading} disabled={isAnalisisLoading} onClick={handleContinue}>
                  {texts.continue}
                </ButtonAdorned>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

FichaIngreso.propTypes = {
  data: PropTypes.shape(),
};

FichaIngreso.defaultProps = {
  data: {},
};

export default FichaIngreso;
