import API from '../infraestructure/api';
import ApiError from './apiError';

export async function getServiceReportIngxarea(data) {
  try {
    const {
      dateInit, dateEnd, all, archivados,
    } = data;
    const response = await API.get(`/reports/ingresosxarea?dateInit=${dateInit}&dateEnd=${dateEnd}&all=${all}&archivados=${archivados}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/reports/ingresosxarea', message: `Hubo un problema al intentar buscar datos de reporte. ${message}` });
  }
}

export async function getServiceReportEnsayosByQty(data) {
  try {
    const {
      dateInit, dateEnd, all, archivados,
    } = data;
    const response = await API.get(`/reports/ensayosbyqty?dateInit=${dateInit}&dateEnd=${dateEnd}&all=${all}&archivados=${archivados}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/reports/ensayosbyqty', message: `Hubo un problema al intentar buscar datos de reporte. ${message}` });
  }
}

export async function getServiceReportEnsayosByLaboratorista(data) {
  try {
    const {
      dateInit, dateEnd, all, archivados,
    } = data;
    const response = await API.get(`/reports/ensayosbylaboratorista?dateInit=${dateInit}&dateEnd=${dateEnd}&all=${all}&archivados=${archivados}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/reports/ensayosbylaboratorista', message: `Hubo un problema al intentar buscar datos de reporte. ${message}` });
  }
}

export async function getServiceReportEnsayosByDesviacion(data) {
  try {
    const {
      dateInit, dateEnd, all, archivados,
    } = data;
    const response = await API.get(`/reports/ensayosbydesviacion?dateInit=${dateInit}&dateEnd=${dateEnd}&all=${all}&archivados=${archivados}`);
    if (response && response.data) {
      return response.data;
    }
    return {};
  } catch (error) {
    const message = (error && error.response && error.response.data && error.response.data.message) || '';
    throw ApiError({ code: 'api/reports/ensayosbydesviacion', message: `Hubo un problema al intentar buscar datos de reporte. ${message}` });
  }
}
