import React, { useEffect, useState, useContext } from 'react';
import {
  Grid, LinearProgress, Hidden, IconButton, Menu, MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  EditOutlined as EditOutlinedIcon,
  VisibilityOutlined as VisibilityOutlinedIcon,
  ListOutlined as MenuIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton,
} from '@material-ui/data-grid';
import i18n from '@i18n';
import { useEmployees } from '@hooks';
import { Alert, DataGrid } from '@components/atoms';
import AuthContext from '@contexts/AuthContext';

const List = () => {
  const classes = useStyles();
  const history = useHistory();
  const { pages: { employees: { employees: { list: texts } } } } = i18n;
  const { getEmployees, employeeError, isEmployeeLoading } = useEmployees();
  const [employees, setEmployees] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useContext(AuthContext);

  useEffect(async () => {
    setEmployees(await getEmployees());
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: 'title',
      headerName: texts.columns.title,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'name',
      headerName: texts.columns.name,
      flex: 1,
    },
    {
      field: 'lastname',
      headerName: texts.columns.lastname,
      flex: 1,
    },
    {
      field: 'email',
      headerName: texts.columns.email,
      flex: 0.5,
    },
    {
      field: 'rol',
      headerName: 'Rol/es',
      flex: 1,
      valueGetter: (values) => values.row && values.row.roles && values.row.roles.map((rol) => rol.name).join(', '),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: (values) => (
        <>
          <Hidden xsDown>
            {user && user.permissions.admin.update && (
            <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/colaboradores/edit/${values.row.id}`)}>
              <EditOutlinedIcon />
            </IconButton>
            )}
            {user && user.permissions.admin.read && (
            <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/colaboradores/view/${values.row.id}`)}>
              <VisibilityOutlinedIcon />
            </IconButton>
            )}
          </Hidden>
          <Hidden smUp>
            <IconButton className={classes.actionIcons} onClick={(event) => setAnchorEl(event.currentTarget)}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {user && user.permissions.admin.update && (
                <MenuItem onClick={() => history.push(`/e/clientes/edit/${values.row.id}`)}>Editar</MenuItem>
              )}
              {user && user.permissions.admin.read && (
                <MenuItem onClick={() => history.push(`/e/colaboradores/view/${values.row.id}`)}>Ver</MenuItem>
              )}
            </Menu>
          </Hidden>
        </>
      ),
    },
  ];
  const CustomToolbar = () => (
    <>
      <Hidden xsDown>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
      <Hidden smUp>
        <GridToolbarContainer>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
        </GridToolbarContainer>
      </Hidden>
    </>
  );

  return (
    <>
      {employeeError && <Alert>{employeeError.message}</Alert>}
      {isEmployeeLoading ? <LinearProgress /> : (
        <Grid container spacing={2}>
          <DataGrid
            columns={columns}
            rows={employees}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Grid>
      )}
    </>
  );
};

List.propTypes = {
};

List.defaultProps = {
};

const useStyles = makeStyles({
  actionIcons: {
    padding: '4px',
  },
});

export default List;
