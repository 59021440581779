import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import i18n from '@i18n';
import { DataGrid, ButtonAdorned, ChipStep } from '@components/atoms';
import { GridToolbar } from '@material-ui/data-grid';
import { Grid, IconButton, Typography } from '@material-ui/core';
import getDate from '@helpers/utils/getDate';
import constants from '@helpers/constants';
import { DoubleArrowOutlined as DoubleArrowOutlinedIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

const DetalleEnsayosLab = ({ location: { state: { data } }, match: { params: { id } } }) => {
  const { pages: { employees: { reporteria: SubReportsTexts } } } = i18n;
  const { pages: { employees: { analisis: { list: texts } } } } = i18n;
  const [dataTable, setDataTable] = useState([]);
  const [nombreLab, setNombreLab] = useState('');
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (Array.isArray(data) && id) {
      // Filtrar data para encontrar registros donde el id está en los members
      const filteredData = data.filter((item) => item.members.some((member) => member.id === id));
      const laboratorista = filteredData[0].members.filter((member) => member.id === id)[0];
      setDataTable(filteredData);
      setNombreLab(`${laboratorista.name} ${laboratorista.lastname}`);
    }
  }, [id]);

  const Archived = () => (
    <Typography style={{ color: 'red' }}>Archivado</Typography>
  );

  const columns = [
    {
      field: 'customer',
      headerName: texts.columns.customer,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (rowData) => (rowData.value.name),
    },
    {
      field: 'sample',
      headerName: texts.columns.sample,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (rowData) => (rowData.value),
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      type: 'date',
      valueFormatter: (values) => getDate(values.value),
    },
    {
      field: 'number',
      headerName: texts.columns.number,
      flex: 0.4,
    },
    {
      field: 'step',
      headerName: texts.columns.step,
      flex: 0.5,
      renderCell: (rowData) => {
        const step = constants.STEPS_ANALISIS.find((item) => item.step === rowData.row.step);
        return (<ChipStep {...step} />);
      },
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: (rowData) => {
        const { row: { archived } } = rowData;
        return (archived ? <Archived /> : (
          <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/analisis/edit/${rowData.id}`)}>
            <DoubleArrowOutlinedIcon />
          </IconButton>
        ));
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>{`${i18n.appName} - ${SubReportsTexts.subreports.ensayosbydesviacion.title}`}</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>{nombreLab}</Typography>
        </Grid>
      </Grid>
      {data.length !== 0 && (
        <Grid container spacing={2} classes={classes.container} style={{ textAlign: 'center' }}>
          <Grid item xs={12}>
            <DataGrid
              rowsPerPage={10}
              columns={columns}
              rows={dataTable}
              key="id"
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonAdorned
              color="default"
              onClick={() => history.push('/e/reporteria/ensayosbylaboratorista')}
            >
              Volver
            </ButtonAdorned>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  mt1: {
    marginTop: '1em',
  },
  container: {
    padding: theme.spacing(3),
  },
  changeContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.87) !important',
  },
}));

DetalleEnsayosLab.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.arrayOf(),
    }),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      action: PropTypes.string,
      id: PropTypes.string,
    }),
  }),
};

DetalleEnsayosLab.defaultProps = {
  location: {
    state: {
      data: '',
    },
  },
  match: {
    params: {
      action: 'list',
      id: '',
    },
  },
};

export default DetalleEnsayosLab;
