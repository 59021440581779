import * as validator from 'yup';

import i18n from '@i18n';

const createAnalisisSchema = validator.object({
  customer: validator.object({
    id: validator.string().required(i18n.validationSchemas.customer.required),
    name: validator.string(),
  }),
  agreement: validator.boolean(),

  presupuesto: validator.object({
    id: validator.string(),
    number: validator.string(),
  }),
  file: validator
    .array()
    .min(1, i18n.validationSchemas.file.required),
  number: validator
    .string()
    .required(i18n.validationSchemas.analisis.required),
  docsFichaIngreso: validator
    .array()
    .min(1, i18n.validationSchemas.file.required),
  sample: validator
    .string()
    .required(i18n.validationSchemas.sample.required),
  typeArea: validator.object({
    id: validator.string().required(i18n.validationSchemas.typeArea.required),
    name: validator.string(),
  }),
  limitDate: validator
    .string()
    .required(i18n.validationSchemas.initialDate.required),
});

export default createAnalisisSchema;
