import React, { useEffect, useState } from 'react';
import {
  Grid, LinearProgress, Hidden, IconButton, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  DoubleArrowOutlined as DoubleArrowOutlinedIcon,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  GridToolbarContainer, GridToolbarColumnsButton, GridToolbarDensitySelector, GridToolbarFilterButton,
} from '@material-ui/data-grid';
import i18n from '@i18n';
import { useAnalisis } from '@hooks';
import { Alert, ChipStep, DataGrid } from '@components/atoms';
import getDate from '@helpers/utils/getDate';
import constants from '@helpers/constants';

const Archived = () => (
  <Typography style={{ color: 'red' }}>Archivado</Typography>
);

const List = () => {
  const classes = useStyles();
  const history = useHistory();
  const { pages: { employees: { analisis: { list: texts } } } } = i18n;
  const { getAnalisisAll, analisisError, isAnalisisLoading } = useAnalisis();
  const [analisis, setAnalisis] = useState(null);

  useEffect(async () => {
    setAnalisis(await getAnalisisAll());
  }, []);

  const columns = [
    {
      field: 'customer',
      headerName: texts.columns.customer,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (data) => (data.value.name),
    },
    {
      field: 'sample',
      headerName: texts.columns.sample,
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (data) => (data.value),
    },
    {
      field: 'createdAt',
      headerAlign: 'center',
      headerName: texts.columns.createdAt,
      align: 'center',
      flex: 0.6,
      type: 'date',
      valueFormatter: (values) => getDate(values.value),
    },
    {
      field: 'number',
      headerName: texts.columns.number,
      flex: 0.4,
    },
    {
      field: 'step',
      headerName: texts.columns.step,
      flex: 0.5,
      renderCell: (data) => {
        const step = constants.STEPS_ANALISIS.find((item) => item.step === data.row.step);
        return (<ChipStep {...step} />);
      },
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 0.5,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data) => {
        const { row: { archived } } = data;
        return (archived ? <Archived /> : (
          <IconButton className={classes.actionIcons} onClick={() => history.push(`/e/analisis/edit/${data.id}`)}>
            <DoubleArrowOutlinedIcon />
          </IconButton>
        ));
      },
    },
  ];
  const CustomToolbar = () => (
    <>
      <Hidden xsDown>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarFilterButton />
        </GridToolbarContainer>
      </Hidden>
      <Hidden smUp>
        <GridToolbarContainer>
          <GridToolbarFilterButton />
          <GridToolbarColumnsButton />
        </GridToolbarContainer>
      </Hidden>
    </>
  );

  return (
    <>
      {analisisError && <Alert>{analisisError.message}</Alert>}
      {isAnalisisLoading && <LinearProgress />}
      {analisis && (
        <Grid container spacing={2}>
          <DataGrid
            sortModel={[{ field: 'createdAt', sort: 'desc' }]}
            columns={columns}
            rows={analisis}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </Grid>
      )}
    </>
  );
};

List.propTypes = {
};

List.defaultProps = {
};

const useStyles = makeStyles({
  actionIcons: {
    padding: '4px',
  },
});

export default List;
